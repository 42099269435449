import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../../util/asyncComponent";

const Dashboard = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      {/* <Redirect exact from={`${match.url}/`} to={`${match.url}/chart`} /> */}
      <Route
        path={`${match.url}`}
        component={asyncComponent(() => import("./routes/Crypto"))}
      />
    </Switch>
  </div>
);

export default Dashboard;
