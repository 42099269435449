import { all, put, call, take, fork } from "redux-saga/effects";

import {
  signInStartAdmin,
  signInSuccessAdmin,
  userTokenSetAdmin,
  signInErrorAdmin,
  logoutLoaderAdmin,
  logoutSuccessAdmin,
  logoutErrorAdmin,
} from "../../actions";
import { apiAdmin } from "../../service/administrator/CommonAdministrator";

import {
  SIGNIN_ADMINISTRATOR,
  USER_LOGOUT_ADMINISTRATOR,
} from "constants/ActionTypes";

function* userSignIn(email, password) {
  yield put(signInErrorAdmin(""));
  try {
    yield put(signInStartAdmin());
    let response = yield call(apiAdmin.userSignInAdmin, email, password); //call APi
    if (response.data.Success) {
      localStorage.clear();
      yield put(signInSuccessAdmin(true));
      yield put(userTokenSetAdmin(response.data.Token));
    } else {
      yield put(signInErrorAdmin(response.data.Message));
    }
  } catch (e) {
    yield put(signInErrorAdmin("Has an error, please try again !"));
  }
}

function* SignInSaga() {
  while (true) {
    const { payload } = yield take(SIGNIN_ADMINISTRATOR);
    const { email, password } = payload;
    yield fork(userSignIn, email, password);
  }
}

function* signOut() {
  try {
    yield put(logoutLoaderAdmin());
    localStorage.clear();
    sessionStorage.clear();
    yield put(userTokenSetAdmin(null));
    yield put(logoutSuccessAdmin());
  } catch (error) {
    yield put(logoutErrorAdmin(error));
  }
}
function* signOutSaga() {
  while (true) {
    yield take(USER_LOGOUT_ADMINISTRATOR);
    yield fork(signOut);
  }
}

export default function* rootSaga() {
  yield all([fork(signOutSaga), fork(SignInSaga)]);
}
