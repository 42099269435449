import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "@material-ui/core/CircularProgress";
import { validateEmail } from "constants/CheckValidate";
import {
  signInAdministrator,
  changeSignInErrorAdmin,
  signInSuccessAdmin,
} from "actions/administrator/auth/Auth";
import { useForm } from "react-hook-form";

const SignIn = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const { loader, token, errorSignIn, successSignIn, messages } = useSelector(
    ({ signInAdministrator }) => signInAdministrator
  );
  const { register, errors, handleSubmit: handleSubmitForm } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });
  const [showMessages, setShowMessages] = useState("");
  const [isErrorMessages, setIsErrorMessages] = useState(false);

  const handleSignIn = () => {
    if (validateEmail(email) && !!password) {
      dispatch(signInAdministrator({ email, password }));
    }
  };

  useEffect(() => {
    if (token !== null) {
      props.history.push("/administrator");
    } else {
      dispatch(signInSuccessAdmin(false));
      props.history.push("/administrator/signin");
    }

    if (errorSignIn) {
      dispatch(changeSignInErrorAdmin(false));
    }
  }, [token, errorSignIn, successSignIn]);
  useEffect(() => {
    setShowMessages(messages);
  }, [messages]);
  return (
    <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">
        <div className="app-logo-content d-flex align-items-center justify-content-center">
          <Link className="logo-lg" to="/" title="Jambo">
            <img
              src={require("assets/images/ez4shop-booking-new.png")}
              alt="jambo"
              title="jambo"
              className="img-fluid"
            />
          </Link>
        </div>

        <div className="app-login-content">
          <div className="app-login-header mb-4">
            <h1>
              <IntlMessages id="appModule.email" />
            </h1>
          </div>
          <div className="app-login-form">
            <div className="field">
              <TextField
                required
                id="outlined-required"
                type="email"
                className=""
                variant="outlined"
                error={errors.Email ? true : false}
                label="Email"
                fullWidth
                name="Email"
                value={email}
                onChange={(e) => {
                  setIsErrorMessages(false);
                  errors.Email = false;
                  setEmail(e.target.value);
                }}
                inputRef={register({
                  required: true,
                })}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    setIsErrorMessages(true);
                    handleSubmitForm(handleSignIn)();
                  }
                }}
              />
              {errors.Email && (
                <span
                  style={{
                    display: "inline-block",
                    marginTop: 10,
                    color: "red",
                  }}
                >
                  You need to enter your email address
                </span>
              )}
              {email && !validateEmail(email) && (
                <span
                  style={{
                    display: "inline-block",
                    marginTop: 10,
                    color: "red",
                  }}
                >
                  Invalid email
                </span>
              )}
              <div className="mt-4">
                <TextField
                  required
                  id="outlined-password-input"
                  className=""
                  variant="outlined"
                  error={errors.Password ? true : false}
                  label="Password"
                  type="password"
                  fullWidth
                  name="Password"
                  value={password}
                  onChange={(e) => {
                    errors.Password = false;
                    setIsErrorMessages(false);
                    setPassword(e.target.value);
                  }}
                  inputRef={register({
                    required: true,
                  })}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      setIsErrorMessages(true);
                      handleSubmitForm(handleSignIn)();
                    }
                  }}
                />

                {errors.Password && (
                  <span
                    style={{
                      display: "inline-block",
                      marginTop: 10,
                      color: "red",
                    }}
                  >
                    You need to enter your password
                  </span>
                )}
              </div>
            </div>

            <div className="mb-3 d-flex align-items-center justify-content-between">
              <Button
                onClick={() => {
                  setIsErrorMessages(true);
                  handleSubmitForm(handleSignIn)();
                }}
                variant="contained"
                style={{
                  marginTop: 20,
                  backgroundColor: "#1d1d1d",
                  color: "#FFFFFF",
                }}
              >
                <IntlMessages id="appModule.signIn" />
              </Button>
            </div>
            {isErrorMessages && !errors.Email && !errors.Password && (
              <p
                style={{ display: "inline-block", marginTop: 10, color: "red" }}
              >
                {showMessages}
              </p>
            )}
          </div>
        </div>
      </div>
      {loader && (
        <div className="loader-view">
          <CircularProgress />
        </div>
      )}
      {/* {showMessage && NotificationManager.error(alertMessage)} */}
      <NotificationContainer />
    </div>
  );
};

export default SignIn;
