import * as Types from 'constants/ActionTypes';
import Moment from "moment";

const initialState = {
  isLoading: false,
  product: {
    Name: "",
    ShortDescription: "",
    MetaKeywords: "",
    MetaDescription: "",
    MetaTitle: "",
    Sku: "",
    CallForPrice: false,
    Published: false,
    Deleted: false,
    FullDescription: "",
    CreatedOnUtc: Moment()
      .format("yyyy-MM-DDTHH:mm:ss")
      .toString(),
    UpdatedOnUtc: Moment()
      .format("yyyy-MM-DDTHH:mm:ss")
      .toString(),
    VendorId: 0,
    ApprovedRatingSum: 0,
    AllowCustomerReviews: false,
    ApprovedTotalReviews: 0,
    RequireOtherProducts: false,
    RequiredProductIds: "",
    ProductPriceBookingOrder: {},
    Product_ProductAttributes: [
    ],
    ProductCategories: []
  },
  Product_ProductAttributes: []
}

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.PRODUCT_LOADER: {
      return {
        ...state,
        isLoading: action.payload
      }
    }

    case Types.GET_PRODUCT_SUCCES: {
      return {
        ...state,
        product: action.payload,
        isLoading: false
      }
    }

    case Types.ON_SUCCESS_ADD_ATTRIBUTE: {
      return {
        ...state,
        Product_ProductAttributes: action.payload
      }
    }

    default:
      return state
  }
}

export default productReducer;