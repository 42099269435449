import React from 'react';
import IntlMessages from 'util/IntlMessages';

const Error404 = () => {
  return (
    <div className="page-error-container animated slideInUpTiny animation-duration-3">
      <p className="error-404">404</p>
      <p className="error-text">Oops, an error has occurred. Page not found!</p>
    </div>
  )
}

export default Error404;
