import React, { useEffect, useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import URLSearchParams from 'url-search-params'
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import SideNavOption from './SideNavOption';
import { changeDirection, setDarkTheme, setThemeColor } from 'actions/index';
import {
  AMBER,
  BLUE,
  CYAN,
  DARK_AMBER,
  DARK_BLUE,
  DARK_CYAN,
  DARK_DEEP_ORANGE,
  DARK_DEEP_PURPLE,
  DARK_GREEN,
  DARK_INDIGO,
  DARK_PINK,
  DEEP_ORANGE,
  DEEP_PURPLE,
  GREEN,
  INDIGO,
  PINK
} from 'constants/ThemeColors';


const ColorOption = (props) => {

  const dispatch = useDispatch();
  const [drawerStatus, setDrawerStatus] = useState(false);
  const { themeColor, darkTheme, isDirectionRTL } = useSelector(({ settings }) => settings);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    if (params.has("theme-name")) {
      document.body.classList.add(params.get('theme-name'));
    } else {
      document.body.classList.add(themeColor);
    }
  }, [props.location.search, themeColor]);

  const toggleCustomizer = () => {
    setDrawerStatus(!drawerStatus);
  };
  const closeCustomizer = () => {
    setDrawerStatus(false);
  };
  const handleThemeColor = (colorCode) => {
    dispatch(setThemeColor(colorCode));
    const body = document.body.classList;
    body.remove(themeColor);
    body.remove('dark-theme');
    body.add(colorCode);
  };

  const handleDarkTheme = () => {
    dispatch(setDarkTheme());
    const body = document.body.classList;
    body.toggle(themeColor);
    body.toggle('dark-theme');
  };

  return (

    <div className="theme-option">

    </div>
  );
};


export default withRouter(ColorOption);

