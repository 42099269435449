import {
  ON_ADD_ATTRIBUTE,
  ON_SUCCESS_ADD_ATTRIBUTE,
  LIST_PRODUCTS,
  GET_LIST_PRODUCTS,
  GET_PRODUCT,
  GET_PRODUCT_SUCCES,
  PRODUCT_LOADER
} from 'constants/ActionTypes'

export const isLoadingProduct = (payload) => {
  return {
    type: PRODUCT_LOADER,
    payload
  }
}

export const getProduct = (payload) => {
  return {
    type: GET_PRODUCT,
    payload
  }
}

export const getProductSucces = (payload) => {
  return {
    type: GET_PRODUCT_SUCCES,
    payload
  }
}

export const onAddAttribute = (payload) => {
  return {
    type: ON_ADD_ATTRIBUTE,
    payload
  }
}

export const onSuccesAddAttribute = (payload) => {
  return {
    type: ON_SUCCESS_ADD_ATTRIBUTE,
    payload
  }
}