import * as Types from "constants/ActionTypes";

export const signInAdministrator = (payload) => {
  return {
    type: Types.SIGNIN_ADMINISTRATOR,
    payload,
  };
};

export const logoutAdministrator = () => {
  return {
    type: Types.USER_LOGOUT_ADMINISTRATOR,
  };
};

export const logoutLoaderAdmin = () => {
  return {
    type: Types.LOGOUT_LOADER,
  };
};

export const logoutSuccessAdmin = () => {
  return {
    type: Types.LOGOUT_SUCCESS,
  };
};

export const logoutErrorAdmin = (payload) => {
  return {
    type: Types.LOGOUT_ERROR,
    payload,
  };
};

export const signInStartAdmin = () => {
  return {
    type: Types.SIGNIN_ADMINISTRATOR_START,
  };
};

export const signInErrorAdmin = (payload) => {
  return {
    type: Types.SIGN_IN_ADMINISTRATOR_ERROR,
    payload,
  };
};

export const signInSuccessAdmin = (payload) => {
  return {
    type: Types.SIGN_IN_ADMINISTRATOR_SUCCESS,
    payload,
  };
};

export const setAuthUserAdmin = (payload) => {
  return {
    type: Types.SET_AUTH_USER_ADMINISTRATOR,
    payload,
  };
};

export const userTokenSetAdmin = (payload) => {
  return {
    type: Types.SET_TOKEN_ADMINISTRATOR,
    payload,
  };
};

export const changeSignInErrorAdmin = (payload) => {
  return {
    type: Types.CHANGE_SIGN_IN_ADMINISTRATOR_ERROR,
    payload,
  };
};
