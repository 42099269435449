const checkBusinessType = (type) => {
  var booleanType = false;
  const businessType = JSON.parse(localStorage.getItem("businessType"));
  if (businessType && businessType.length > 0) {
    if (businessType.indexOf(type) !== -1) booleanType = true;
    else booleanType = false;
  }
  return booleanType;
};

export { checkBusinessType };
