import { all, put, call, take, fork } from "redux-saga/effects";

import {
  signInStart,
  SignInSuccess,
  userTokenSet,
  SignInError,
  logoutLoader,
  logoutSuccess,
  logoutError,
  userBusinessTypeSet,
  userBusinessCustomerSet,
  userBusinessPermissionSet,
  allCustomer,
} from "../../actions";
import { api } from "../../service/bookingOrderAdmin/CommonVendorAdmin";

import {
  SIGNIN_BOOKING_ORDER_ADMIN,
  USER_LOGOUT_BOOKING_ORDER_ADMIN,
} from "constants/ActionTypes";

function* userSignIn(email, password) {
  yield put(SignInError(""));
  try {
    yield put(signInStart());
    let response = yield call(api.userSignIn, email, password); //call APi
    if (response.data.Success) {
      localStorage.clear();
      yield put(SignInSuccess(true));
      yield put(userTokenSet(response.data.Token));
      yield put(userBusinessTypeSet(response.data.BusinessTypes));
      if (response.data.FullPermission)
        yield put(userBusinessCustomerSet(response.data.CustomerBusiness));
      else {
        let customerCommon = {
          ...response.data.CustomerCommon,
          SubDomain: response.data.CustomerBusiness.SubDomain,
          LastName: response.data.CustomerCommon.ShippingAddress.LastName,
          FirstName: response.data.CustomerCommon.ShippingAddress.FirstName,
        };
        yield put(userBusinessCustomerSet(customerCommon));
      }
      yield put(allCustomer(response.data));
      yield put(userBusinessPermissionSet(response.data));
    } else {
      yield put(SignInError(response.data.Message));
    }
  } catch (e) {
    yield put(SignInError("This email address is not registered"));
  }
}

function* SignInSaga() {
  while (true) {
    const { payload } = yield take(SIGNIN_BOOKING_ORDER_ADMIN);
    const { email, password } = payload;
    yield fork(userSignIn, email, password);
  }
}

function* signOut() {
  try {
    yield put(logoutLoader());
    localStorage.clear();
    sessionStorage.clear();
    yield put(userTokenSet(null));
    yield put(logoutSuccess());
  } catch (error) {
    yield put(logoutError(error));
  }
}
function* signOutSaga() {
  while (true) {
    yield take(USER_LOGOUT_BOOKING_ORDER_ADMIN);
    yield fork(signOut);
  }
}

export default function* rootSaga() {
  yield all([fork(signOutSaga), fork(SignInSaga)]);
}
