import { all } from "redux-saga/effects";
import authSagasAdminstrator from "./administrator/Auth";
import authSagasBookingOrderAdmin from "./bookingOrderAdmin/Auth";
import ProductSaga from "./bookingOrderAdmin/Product"

export default function* rootSaga(getState) {
  yield all([
    authSagasAdminstrator(),
    authSagasBookingOrderAdmin(),
    ProductSaga()
  ]);
}
