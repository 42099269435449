import React, { useEffect, useState } from "react";
import CustomScrollbars from "util/CustomScrollbars";
import Navigation from "../../components/Navigation";
import { checkBusinessType } from "../../util/businessType";
const SideBarContent = ({ permissionType, fullPermission }) => {
  const [templateType, setTemplateType] = useState(true)
  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem("customerBusiness")) && JSON.parse(localStorage.getItem("customerBusiness")).TypeTemplate === ("professional-services-template-2" || "professional-services-template-3")
    ) {
      setTemplateType(false)
    } else {
      setTemplateType(true)

    }
  })
  const type =
    typeof window !== undefined &&
    localStorage.getItem("customerBusiness") &&
    JSON.parse(localStorage.getItem("customerBusiness"));
  const [navigationMenus, setNavigationMenus] = useState([
    {
      name: "Business Details",
      type: "collapse",
      icon: "view-dashboard",
      children: [
        {
          name: "General Settings",
          type: "item",
          icon: "code-setting",
          link: "/bookingOrderAdmin/app/business-details/general-settings",
        },
        {
          name: "Payment Settings",
          type: "item",
          icon: "code-setting",
          link: "/bookingOrderAdmin/app/business-details/payment-settings",
        },
        {
          name: "setting.stores",
          type: "item",
          icon: "store",
          link: "/bookingOrderAdmin/app/business-details/list-stores/list",
        },
      ],
    },
    {
      name: "homePage.homePageName",
      type: "collapse",
      icon: "home",
      children: [
        {
          name: "homePage.designWesbite",
          type: "item",
          icon: "shopping-basket",
          link: "",
          // type && type.CompanyType && type.CompanyType === "beauty-shops"
          //   ? "/bookingOrderAdmin/app/home-page/design-spa"
          //   : type &&
          //     type.CompanyType &&
          //     type.CompanyType === "professional-services"
          //   ? "/bookingOrderAdmin/app/home-page/design-pro"
          //   : "/bookingOrderAdmin/app/home-page/design",
        },
      ],
    },
    {
      name: "Bookings / Orders",
      type: "collapse",
      icon: "book-image",
      children: [
        {
          name: "Orders ",
          type: "collapse",
          icon: "check-square",
          children: [
            {
              name: "Categories",
              type: "item",
              icon: "check-square",
              link:
                "/bookingOrderAdmin/app/bookingOrder/list-categories/list/order",
            },
            {
              name: "Product Attributes",
              type: "item",
              icon: "check-square",
              link:
                "/bookingOrderAdmin/app/bookingOrder/list-attributes/list/order",
            },
            {
              name: "pages.product.listProduct",
              type: "item",
              icon: "check-square",
              link:
                "/bookingOrderAdmin/app/bookingOrder/list-products/list/order",
            },
            {
              name: "Ordered",
              type: "item",
              icon: "check-square",
              link: "/bookingOrderAdmin/app/bookingOrder/orders/list/ordered",
            },
          ],
        },
        {
          name: "Booking",
          type: "collapse",
          icon: "check-square",
          children: [
            {
              name: "Equipment",
              type: "item",
              icon: "check-square",
              link:
                "/bookingOrderAdmin/app/bookingOrder/booking/available-equipment",
            },
            {
              name: "Services",
              type: "item",
              icon: "check-square",
              link: "/bookingOrderAdmin/app/bookingOrder/booking/services",
            },
            {
              name: "Time Slot",
              type: "item",
              icon: "check-square",
              link: "/bookingOrderAdmin/app/bookingOrder/booking/time-slot",
            },
            //Check Type Business
            type?.CompanyType !== "restaurant"
              ? {
                name: "Service Staff",
                type: "item",
                icon: "check-square",
                link:
                  "/bookingOrderAdmin/app/bookingOrder/booking/service-staff",
              }
              : {},
            {
              name: "Booked",
              type: "item",
              icon: "check-square",
              link: "/bookingOrderAdmin/app/bookingOrder/booking/booked",
            },
          ],
        },
        {
          name: "Design Layout",
          type: "item",
          icon: "shopping-basket",
          link: "/bookingOrderAdmin/app/bookingOrder/design-layout",
        },
        {
          name: "Insights",
          type: "item",
          icon: "money-box",
          link: "/bookingOrderAdmin/app/bookingOrder/insights",
        },
      ],
    },
    {
      name: "sidebar.voucherPromotion",
      type: "collapse",
      icon: "card-giftcard",
      children:
        templateType &&
        [
          {
            name: "voucherPromotion.templateVoucher",
            type: "item",
            icon: "globe-alt",
            link: "/bookingOrderAdmin/app/voucher/voucher-template",
          },
          {
            name: "voucherPromotion.voucher",
            type: "item",
            icon: "card-giftcard",
            link: "/bookingOrderAdmin/app/voucher/create-voucher/list",
          },
          {
            name: "voucherPromotion.soldVouchers",
            type: "item",
            icon: "card-giftcard",
            link: "/bookingOrderAdmin/app/voucher/voucher-management",
          },
          {
            name: "voucherPromotion.designWebsite",
            type: "item",
            icon: "shopping-basket",
            link: "/bookingOrderAdmin/app/voucher/design-layout",
          },
          {
            name: "voucherPromotion.promotion",
            type: "item",
            icon: "card-giftcard",
            link: "/bookingOrderAdmin/app/voucher/promotion",
          },
          {
            name: "Insights",
            type: "item",
            icon: "money-box",
            link: "/bookingOrderAdmin/app/voucher/insights",
          },
        ],
    },
    {
      name: "Membership",
      type: "item",
      icon: "check-square",
      link: "/bookingOrderAdmin/app/membership/list",
    },
    {
      name: "sidebar.customer",
      type: "collapse",
      icon: "account-box",
      children: [
        {
          name: "Customers",
          type: "item",
          icon: "face",
          link: "/bookingOrderAdmin/app/customer/list-customers",
        },
      ],
    },
    {
      name: "sidebar.staff",
      type: "collapse",
      icon: "account-box",
      children: [
        {
          name: "Users",
          type: "item",
          icon: "accounts",
          link: "/bookingOrderAdmin/app/staff/list-users",
        },
        {
          name: "Role",
          type: "item",
          icon: "shield-security",
          link: "/bookingOrderAdmin/app/staff/list-roles",
        },
      ],
    },
    {
      name: "E-mail Marketing",
      type: "collapse",
      icon: "shopping-basket",
      children: [
        {
          name: "Template",
          type: "item",
          icon: "money",
          link: "/bookingOrderAdmin/app/email-marketing/list",
        },
        {
          name: "Email SMTP ",
          type: "item",
          icon: "email",
          link: "/bookingOrderAdmin/app/email-marketing/email-smtp/list",
        },
      ],
    },
    {
      name: "pages.dashboard",
      type: "item",
      icon: "view-dashboard",
      link: "/bookingOrderAdmin/app/dashboard",
    },
  ]);
  useEffect(() => {
    let navigationMenusClone = [...navigationMenus];
    if (type) {
      if (type.IsWebsite) {
        navigationMenusClone.splice(1, 1);
        setNavigationMenus(navigationMenusClone);
      } else {
        if (type.CompanyType === "beauty-shops") {
          navigationMenusClone[1].children[0].link =
            "/bookingOrderAdmin/app/home-page/design-spa";
          setNavigationMenus(navigationMenusClone);
        } else if (type.CompanyType === "professional-services") {
          navigationMenusClone[1].children[0].link =
            "/bookingOrderAdmin/app/home-page/design-pro";
          setNavigationMenus(navigationMenusClone);
        } else {
          navigationMenusClone[1].children[0].link =
            "/bookingOrderAdmin/app/home-page/design";
          setNavigationMenus(navigationMenusClone);
        }
      }
    }
  }, [typeof window !== undefined && localStorage.getItem("customerBusiness")]);
  useEffect(() => {
    checkPermissionParent();
  }, [permissionType, fullPermission]);
  const checkPermissionParent = () => {
    if (fullPermission === false) {
      var catagory = [];
      permissionType.forEach((item) => {
        catagory.push(item.Category);
      });
      const catagoryList = Array.from(new Set(catagory));
      const permissionListFilter = catagoryList.map((item) => {
        var temp = [];
        permissionType.forEach((permission) => {
          if (item === permission.Category) {
            temp.push(permission);
          }
        });
        return {
          category: item,
          listPermission: temp,
        };
      });
      permissionListFilter.forEach((item) => {
        switch (item.category) {
          case "BusinessDetail":
            item.category = "Business Details";
            break;
          case "Homepage":
            item.category = "homePage.homePageName";
            break;
          case "BookingOrder":
            item.category = "Bookings / Orders";
            break;
          case "VoucherPromotion":
            item.category = "sidebar.voucherPromotion";
            break;
          case "CustomerUser":
            item.category = "sidebar.customer";
            break;
          case "EmailMarketing":
            item.category = "E-mail Marketing";
            break;
        }
      });
      const dashboardNav = [
        navigationMenus[
        navigationMenus.findIndex((item) => item.name === "pages.dashboard")
        ],
      ];
      const permissionList = navigationMenus.filter((item) => {
        return permissionListFilter.some(
          (permission) => permission.category === item.name
        );
      });
      setNavigationMenus([...permissionList, ...dashboardNav]);
      checkPermissionChild(permissionListFilter);
    }
  };
  const checkPermissionChild = (permissionListFilter) => {
    permissionListFilter.forEach((items) => {
      items.listPermission.forEach((item) => {
        switch (item.PermissionRecordName) {
          case "Common-GeneralSetting":
            item.PermissionRecordName = "General Settings";
            break;
          case "Common-PaymentSetting":
            item.PermissionRecordName = "Payment Settings";
            break;
          case "Common-Stores":
            item.PermissionRecordName = "setting.stores";
            break;
          case "Common-Design":
            item.PermissionRecordName = "homePage.designWesbite";
            break;
          case "Common-Customers":
            item.PermissionRecordName = "Customers";
            break;
          case "Common-EmailTemplate":
            item.PermissionRecordName = "Template";
            break;
          case "Common-Dashboard":
            item.PermissionRecordName = "pages.dashboard";
            break;
          case "VoucherPromotion-VoucherTemplate":
            item.PermissionRecordName = "voucherPromotion.templateVoucher";
            break;
          case "VoucherPromotion-CreateVoucher":
            item.PermissionRecordName = "voucherPromotion.voucher";
            break;
          case "VoucherPromotion-VoucherManagement":
            item.PermissionRecordName = "voucherPromotion.soldVouchers";
            break;
          case "VoucherPromotion-DesignLayout":
            item.PermissionRecordName = "voucherPromotion.designWebsite";
            break;
          case "VoucherPromotion-Promotions":
            item.PermissionRecordName = "voucherPromotion.promotion";
            break;
          case "VoucherPromotion-Insights":
            item.PermissionRecordName = "Insights";
            break;
        }
      });
      if (items.category === "Bookings / Orders") {
        items.listPermission.forEach((item) => {
          switch (item.PermissionRecordName) {
            case "Order-Categories":
              item.PermissionRecordName = "Categories";
              break;
            case "Order-ProductAttribute":
              item.PermissionRecordName = "Product Attributes";
              break;
            case "Order-Products":
              item.PermissionRecordName = "pages.product.listProduct";
              break;
            case "Order-Ordered":
              item.PermissionRecordName = "Ordered";
              break;
            case "Booking-Equipment":
              item.PermissionRecordName = "Equipment";
              break;
            case "Booking-Service":
              item.PermissionRecordName = "Services";
              break;
            case "Booking-TimeSlot":
              item.PermissionRecordName = "Time Slot";
              break;
            case "Booking-Booked":
              item.PermissionRecordName = "Booked";
              break;
            case "BookingOrder-DesignLayout":
              item.PermissionRecordName = "Design Layout";
              break;
            case "BookingOrder-Insights":
              item.PermissionRecordName = "Insights";
              break;
          }
        });
      }
    });
    navigationMenus.forEach((nav, index) => {
      if (nav.name !== "Bookings / Orders")
        permissionListFilter.forEach((permission) => {
          if (nav.name === permission.category) {
            if (nav.children) {
              const navTemp = nav.children.filter((items) => {
                return permission.listPermission.some(
                  (item) => item.PermissionRecordName === items.name
                );
              });
              nav.children = navTemp;
            }
          }
        });
      else {
        const bookingOrderNav = nav.children.filter(
          (item) => item.name === "Orders " || item.name === "Booking"
        );
        let navTemp = [];
        const bookingOrderPermission =
          permissionListFilter[
          permissionListFilter.findIndex(
            (item) => item.category === "Bookings / Orders"
          )
          ];
        if (bookingOrderNav) {
          navTemp = nav.children.filter((items) => {
            return bookingOrderPermission.listPermission.some(
              (item) => item.PermissionRecordName === items.name
            );
          });
        }
        nav.children = [...bookingOrderNav, ...navTemp];
      }
    });
    //Permission booking/order
    const bookingOrderNav = {
      ...navigationMenus[
      navigationMenus.findIndex((item) => item.name === "Bookings / Orders")
      ],
    };
    const bookingOrderPermission =
      permissionListFilter[
      permissionListFilter.findIndex(
        (item) => item.category === "Bookings / Orders"
      )
      ];
    bookingOrderNav.children.forEach((nav, index) => {
      bookingOrderPermission.listPermission.forEach((permission) => {
        if (nav.children) {
          const navBookingOrder = nav.children.filter((items) => {
            return permission.PermissionRecordName === items.name;
          });
          if (navBookingOrder.length > 0) {
            bookingOrderNav.children[index].children = navBookingOrder;
          }
        }
      });
    });
  };
  if (checkBusinessType("BookingOrder") === false) {
    navigationMenus.forEach((item, index) => {
      if (item.name === "Bookings / Orders") {
        navigationMenus.splice(index, 1);
      }
    });
  }
  if (checkBusinessType("VoucherPromotion") === false) {
    navigationMenus.forEach((item, index) => {
      if (item.name === "sidebar.voucherPromotion") {
        navigationMenus.splice(index, 1);
      }
    });
  }
  return (
    <CustomScrollbars className="scrollbar">
      <Navigation menuItems={navigationMenus} />
    </CustomScrollbars>
  );
};

export default SideBarContent;
