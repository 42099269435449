import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import { useDispatch } from "react-redux";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { logoutAdministrator } from "actions/administrator/auth/Auth";
import { logoutBookingOrderAdmin } from "actions/bookingOrderAdmin/auth/Auth";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import * as Urls from "constants/Config";
import { useSelector } from "react-redux";

const UserInfo = ({ role }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [anchorE1, setAnchorE1] = useState(null);
  const [open, setOpen] = useState(false);
  const [fullName, setFullName] = useState("");
  const [image, setImage] = useState("");
  const { user } = useSelector(({ User }) => User);
  useEffect(() => {
    var fullNameClone = JSON.parse(localStorage.getItem("customerBusiness"));
    if (fullNameClone) {
      setFullName(fullNameClone.FirstName + " " + fullNameClone.LastName);
      setImage(fullNameClone.VirtualPath);
    }
  }, [
    typeof windown !== undefined &&
      JSON.parse(localStorage.getItem("customerBusiness")),
  ]);
  const handleClick = (event) => {
    setOpen(true);
    setAnchorE1(event.currentTarget);
  };

  const handleRequestClose = () => {
    setOpen(false);
  };
  const handleRequestOpenProfile = () => {
    history.push("/bookingOrderAdmin/app/profile");
    setOpen(false);
  };

  return (
    <div className="user-profile d-flex flex-row align-items-center">
      <Avatar
        alt="..."
        className="user-avatar "
        src={`${Urls.API_URL}${
          user && user.VirtualPath ? user.VirtualPath : image
        }`}
      />
      <div className="user-detail">
        <h4 className="user-name d-flex" onClick={handleClick}>
          <span className="text-truncate">
            {user ? user.FirstName + " " + user.LastName : fullName}
          </span>{" "}
          <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
        </h4>
      </div>
      <Menu
        className="user-info"
        id="simple-menu"
        anchorEl={anchorE1}
        open={open}
        onClose={handleRequestClose}
        PaperProps={{
          style: {
            minWidth: 120,
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
      >
        {role === "bookingOrderAdmin" && (
          <MenuItem onClick={handleRequestOpenProfile}>
            <i className="zmdi zmdi-account zmdi-hc-fw mr-2" />
            <IntlMessages id="Profile" />
          </MenuItem>
        )}
        {/* <MenuItem onClick={handleRequestClose}>
          <i className="zmdi zmdi-settings zmdi-hc-fw mr-2" />
          <IntlMessages id="popup.setting" />
        </MenuItem> */}
        <MenuItem
          onClick={() => {
            handleRequestClose();
            {
              role === "administrator" && dispatch(logoutAdministrator());
              role === "bookingOrderAdmin" &&
                dispatch(logoutBookingOrderAdmin());
            }
          }}
        >
          <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />

          <IntlMessages id="popup.logout" />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserInfo;
