import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import UserInfo from "components/UserInfo";
import {
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from "constants/ActionTypes";
import { toggleCollapsedNav, updateWindowWidth } from "actions/Setting";
import AdministratorSideBarContent from "./AdministratorSideBarContent";
import BookingOrderAdminSideBarContent from "./BookingOrderAdminSideBarContent";

const SideBar = ({ match }) => {
  const dispatch = useDispatch();
  const { drawerType, width, navigationStyle } = useSelector(
    ({ settings }) => settings
  );
  const { navCollapsed } = useSelector(({ common }) => common);

  useEffect(() => {
    window.addEventListener("resize", () => {
      dispatch(updateWindowWidth(window.innerWidth));
    });
  }, [dispatch]);

  const onToggleCollapsedNav = (e) => {
    dispatch(toggleCollapsedNav());
  };

  let drawerStyle = drawerType.includes(FIXED_DRAWER)
    ? "d-xl-flex"
    : drawerType.includes(COLLAPSED_DRAWER)
    ? ""
    : "d-flex";
  let type = "permanent";
  if (
    drawerType.includes(COLLAPSED_DRAWER) ||
    (drawerType.includes(FIXED_DRAWER) && width < 1200)
  ) {
    type = "temporary";
  }

  if (navigationStyle === HORIZONTAL_NAVIGATION) {
    drawerStyle = "";
    type = "temporary";
  }
  const permissionType = sessionStorage.getItem("PermissionsForUser")
    ? JSON.parse(sessionStorage.getItem("PermissionsForUser"))
    : [];
  const fullPermission = sessionStorage.getItem("FullPermission")
    ? JSON.parse(sessionStorage.getItem("FullPermission"))
    : true;
  const typeCustomer =
    typeof window !== undefined && localStorage.getItem("customerBusiness")
      ? JSON.parse(localStorage.getItem("customerBusiness"))
      : "";
  return (
    <div className={`app-sidebar d-none ${drawerStyle}`}>
      <Drawer
        className="app-sidebar-content"
        variant={type}
        open={type.includes("temporary") ? navCollapsed : true}
        onClose={onToggleCollapsedNav}
        classes={{
          paper: "side-nav",
        }}
      >
        {match.url == "/administrator/app" && (
          <>
            <UserInfo role={"administrator"} />
            <AdministratorSideBarContent />
          </>
        )}
        {match.url == "/bookingOrderAdmin/app" && (
          <>
            <UserInfo role={"bookingOrderAdmin"} />
            <BookingOrderAdminSideBarContent
              permissionType={permissionType}
              fullPermission={fullPermission}
              typeCustomer={typeCustomer}
            />
          </>
        )}
      </Drawer>
    </div>
  );
};

export default withRouter(SideBar);
