import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Container,
} from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { PermDeviceInformationSharp } from "@material-ui/icons";
import UploadAdapter from "service/administrator/CommonBookingOrderAdmin";
import { useSelector } from "react-redux";

function Ckeditor({ ShortFullDescription, getContentEditor, specialType }) {
  const [content, setContent] = useState("");
  const { token } = useSelector(
    ({ signInBookingOrderAdmin }) => signInBookingOrderAdmin
  );

  useEffect(() => {
    setContent(ShortFullDescription);
  }, [ShortFullDescription]);

  return (
    <div>
      <CKEditor
        editor={ClassicEditor}
        data={content}
        onReady={(editor) => {
          // You can store the "editor" and use when it is needed.
          console.log("Editor is ready to use!", editor);
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          setContent(data);
          getContentEditor(data);
        }}
        onBlur={(event, editor) => {
          console.log("Blur.", editor);
        }}
        onFocus={(event, editor) => {
          console.log("Focus.", editor);
        }}
        onReady={(editor) => {
          if (editor) {
            editor.ui.view.editable.element.style.height = "280px";
            editor.plugins.get("FileRepository").createUploadAdapter = function(
              loader
            ) {
              return new UploadAdapter(loader, token);
            };
          }
        }}
      />
    </div>
  );
}

export default Ckeditor;
