import axios from "util/Api";
import * as Urls from "constants/Config";

const userSignIn = async (email, password) => {
  const domainName =
    window.location.href.split("?")[1] !== undefined
      ? window.location.href
          .split("?")[1]
          .slice(6, window.location.href.split("?")[1].length)
      : "";
  return await axios
    .post(`${Urls.API_URL}CommonVendorAdmin/SignInAdminBookingOrder`, {
      EmailBuyModule: "string",
      DomainName: sessionStorage
        .getItem("domainName")
        .slice(7, sessionStorage.getItem("domainName").length),
      Username: "string",
      Email: email,
      Password: password,
      RoleId: 0,
    })
    .then((data) => {
      // localStorage.setItem("websiteType",)
      return data;
    })
    .catch(function(error) {
      console.log("Error****:", error.message);
      return error.response;
    });
};

export const api = {
  userSignIn,
};
