import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Settings from "./Settings";
import Auth from "./Auth";
import Common from "./Common";
import SignInAdministrator from "./administrator/auth/SignIn";
import SignInBookingOrderAdmin from "./bookingOrderAdmin/auth/SignIn";
import Product from "./bookingOrderAdmin/Product";
import { reducer as User } from "./administrator/auth/User";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    common: Common,
    auth: Auth,
    signInAdministrator: SignInAdministrator,
    signInBookingOrderAdmin: SignInBookingOrderAdmin,
    product: Product,
    User,
  });
