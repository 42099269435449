import React from "react";
import { Route, Switch } from "react-router-dom";
import Dashboard from "./dashboard";
import asyncComponent from "../../../util/asyncComponent";
import { withRouter } from "react-router";

const Routes = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/dashboard`} component={Dashboard} />
    <Route
      path={`${match.url}/customer`}
      component={asyncComponent(() => import("./customer"))}
    />
    <Route
      path={`${match.url}/website`}
      component={asyncComponent(() => import("./website"))}
    />
    <Route
      path={`${match.url}/email-marketing`}
      component={asyncComponent(() => import("./emailMarketing"))}
    />
    <Route
      path={`${match.url}/settings`}
      component={asyncComponent(() => import("./settings"))}
    />
    <Route
      path={`${match.url}/system`}
      component={asyncComponent(() => import("./system"))}
    />
    <Route
      path={`${match.url}/report`}
      component={asyncComponent(() => import("./report"))}
    />
    <Route
      path={`${match.url}/content-management`}
      component={asyncComponent(() => import("./contentManagement"))}
    />
  </Switch>
);

export default withRouter(Routes);
