const types = {
  LOGIN: "LOGIN",
  UPDATE_USER: "UPDATE_USER",
  LOGOUT: "LOGOUT",
};
export const actions = {
  login: (payload) => {
    return { type: types.LOGIN, payload };
  },
  updateUser: (payload) => {
    return { type: types.UPDATE_USER, payload };
  },
  logout: () => {
    return { type: types.LOGOUT };
  },
};

const initialState = {
  user:
    typeof window !== undefined && localStorage.getItem("customerBusiness")
      ? JSON.parse(localStorage.getItem("customerBusiness"))
      : null,
};
export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.LOGIN: {
      return {
        ...state,
        user: payload,
      };
    }
    case types.UPDATE_USER: {
      return {
        ...state,
        user: payload,
      };
    }
    case type.LOGOUT: {
      return Object.assign({}, initialState);
    }
    default:
      return state;
  }
};
