import request from "util/Api";
import * as Urls from "constants/Config";

const createProductAttribute = (token, productAttribute) => {
  return request({
    url: `${Urls.API_URL}ProductBookingOrderAdmin/CreateProductAttributeWebsiteBookingOrder`,
    data: productAttribute,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

const deleteProductAttribute = (token, Id) => {
  return request({
    url: `${Urls.API_URL}ProductBookingOrderAdmin/DeleteProductAttributeWebsiteBookingOrder`,
    params: {
      attributeId: Id,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: "DELETE",
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

const getProductAttributeAll = (token) => {
  return request({
    url: `${Urls.API_URL}ProductBookingOrderAdmin/GetAllProductAttributeBooked`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
const getProductAttributeAllOrder = (token) => {
  return request({
    url: `${Urls.API_URL}ProductBookingOrderAdmin/GetAllProductAttributeOrdered`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

const updateProductAttribute = (token, productAttribute) => {
  return request({
    url: `${Urls.API_URL}ProductBookingOrderAdmin/UpdateProductAttributeWebsiteBookingOrder`,
    data: productAttribute,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: "PUT",
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

const getProductAttributeId = (Id, token) => {
  return request({
    url: `${Urls.API_URL}ProductBookingOrderAdmin/GetProductAttributeWebsiteBookingOrderById`,
    params: {
      productAttributeId: Id,
    },
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

const getCategory = (token) => {
  return request({
    url: `${Urls.API_URL}ProductBookingOrderAdmin/GetAllCategoriesBookend`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
const getCategoryOrder = (token) => {
  return request({
    url: `${Urls.API_URL}ProductBookingOrderAdmin/GetAllCategoriesOrdered`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

const getProductCategoryId = (Id, token) => {
  return request({
    url: `${Urls.API_URL}ProductBookingOrderAdmin/GetCategoryWebsiteBookingOrderById`,
    params: {
      categoryId: Id,
    },
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

const createCategory = (token, category) => {
  return request({
    url: `${Urls.API_URL}ProductBookingOrderAdmin/CreateCategoryWebsiteBookingOrder`,
    data: category,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

const deleteCategory = (token, Id) => {
  return request({
    url: `${Urls.API_URL}ProductBookingOrderAdmin/DeleteCategoryBookingOrderById`,
    params: {
      categoriesId: Id,
    },
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

const updateProductCategory = (token, category) => {
  return request({
    url: `${Urls.API_URL}ProductBookingOrderAdmin/UpdateCategoryWebsiteBookingOrderById`,
    data: category,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

const getProduct = (token) => {
  return request({
    url: `${Urls.API_URL}ProductBookingOrderAdmin/GetAllProductWebsiteBookingOrder`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

const getProductId = (id, token) => {
  return request({
    url: `${Urls.API_URL}ProductBookingOrderAdmin/GetProductBookingOrderById?productId=${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

const createProduct = (product, token) => {
  return request({
    url: `${Urls.API_URL}ProductBookingOrderAdmin/CreateProductWebsiteBookingOrder`,
    data: product,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

const updateProduct = (product, token) => {
  return request({
    url: `${Urls.API_URL}ProductBookingOrderAdmin/UpdateProductBookingOrder`,
    data: product,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

const deleteProduct = (Id, token) => {
  return request({
    url: `${Urls.API_URL}ProductBookingOrderAdmin/SetDeletedProductWebsiteBookingOrderById`,
    params: {
      id: Id,
    },
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

const createProductPicture = (productPicture, token) => {
  return request({
    url: `${Urls.API_URL}ProductBookingOrderAdmin/InsertOneImageProductWebsiteBookingOrder`,
    data: productPicture,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
const updateProductPicture = (productPicture, token) => {
  return request({
    url: `${Urls.API_URL}ProductBookingOrderAdmin/UpdateOneImageProductWebsiteBookingOrder`,
    data: productPicture,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
const deleteProductPicture = (Id, token) => {
  return request({
    url: `${Urls.API_URL}ProductBookingOrderAdmin/DeleteOneImageProductWebsiteBookingOrder?pictureId=${Id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export {
  deleteProductPicture,
  updateProductPicture,
  createProductAttribute,
  deleteProductAttribute,
  getProductAttributeAll,
  updateProductAttribute,
  getProductAttributeId,
  getCategory,
  getProductCategoryId,
  createCategory,
  deleteCategory,
  updateProductCategory,
  getProduct,
  getProductId,
  createProduct,
  createProductPicture,
  updateProduct,
  deleteProduct,
  getCategoryOrder,
  getProductAttributeAllOrder,
};
