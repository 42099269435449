import * as Types from "constants/ActionTypes";

export const signIn = (payload) => {
  return {
    type: Types.SIGNIN_BOOKING_ORDER_ADMIN,
    payload,
  };
};

export const logoutBookingOrderAdmin = () => {
  return {
    type: Types.USER_LOGOUT_BOOKING_ORDER_ADMIN,
  };
};

export const logoutLoader = () => {
  return {
    type: Types.LOGOUT_LOADER,
  };
};

export const logoutSuccess = () => {
  return {
    type: Types.LOGOUT_SUCCESS,
  };
};

export const logoutError = (payload) => {
  return {
    type: Types.LOGOUT_ERROR,
    payload,
  };
};

export const signInStart = () => {
  return {
    type: Types.SIGNIN_BOOKING_ORDER_ADMIN_START,
  };
};

export const SignInError = (payload) => {
  return {
    type: Types.SIGN_IN_BOOKING_ORDER_ADMIN_ERROR,
    payload,
  };
};

export const SignInSuccess = (payload) => {
  return {
    type: Types.SIGN_IN_ADMINISTRATOR_SUCCESS,
    payload,
  };
};

export const setAuthUser = (payload) => {
  return {
    type: Types.SET_AUTH_USER_BOOKING_ORDER_ADMIN,
    payload,
  };
};

export const userTokenSet = (payload) => {
  return {
    type: Types.SET_TOKEN_BOOKING_ORDER_ADMIN,
    payload,
  };
};
export const userBusinessTypeSet = (payload) => {
  return {
    type: Types.USER_BUSINESS_TYPE_SET,
    payload,
  };
};
export const userBusinessCustomerSet = (payload) => {
  return {
    type: Types.USER_BUSINESS_CUSTOMER_SET,
    payload,
  };
};
export const allCustomer = (payload) => {
  return {
    type: Types.ALL_CUSTOMER,
    payload,
  };
};
export const userBusinessPermissionSet = (payload) => {
  return {
    type: Types.USER_BUSINESS_PERMISSION_SET,
    payload,
  };
};

export const changeSignInError = (payload) => {
  return {
    type: Types.CHANGE_SIGN_IN_BOOKING_ORDER_ADMIN_ERROR,
    payload,
  };
};
