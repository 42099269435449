import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Dashboard from "./dashboard";
import asyncComponent from "../../../util/asyncComponent";
import { withRouter } from "react-router";
import { checkPermission } from "util/CheckPermissionRoot";

const Routes = ({ match }) => {
  const fullPermission = JSON.parse(sessionStorage.getItem("FullPermission"))
    ? JSON.parse(sessionStorage.getItem("FullPermission"))
    : false;
  const permissionList = JSON.parse(
    sessionStorage.getItem("PermissionsForUser")
  )
    ? JSON.parse(sessionStorage.getItem("PermissionsForUser"))
    : [];
  const businessType = JSON.parse(localStorage.getItem("businessType"))
    ? JSON.parse(localStorage.getItem("businessType"))
    : [];
  return (
    <Switch>
      {checkPermission(
        "customer",
        ["Common-Customers"],
        <Route
          path={`${match.url}/customer`}
          component={asyncComponent(() => import("./customer"))}
        />,
        "",
        match,
        fullPermission,
        permissionList,
        businessType
      )}
      {checkPermission(
        "bookingOrder",
        [
          "Order-Categories",
          "BookingOrder-DesignLayout",
          "BookingOrder-Insights",
          "Order-Ordered",
          "Order-ProductAttribute",
          "Order-Products",
          "Booking-Equipment",
          "Booking-Service",
          "Booking-TimeSlot",
          "Booking-Booked",
        ],
        <Route
          path={`${match.url}/bookingOrder`}
          component={asyncComponent(() => import("./bookingOrder"))}
        />,
        "BookingOrder",
        match,
        fullPermission,
        permissionList,
        businessType
      )}
      {checkPermission(
        "voucher",
        [
          "VoucherPromotion-VoucherManagement",
          "VoucherPromotion-DesignLayout",
          "VoucherPromotion-CreateVoucher",
          "VoucherPromotion-Promotions",
          "VoucherPromotion-VoucherTemplate",
          "VoucherPromotion-Insights",
        ],
        <Route
          path={`${match.url}/voucher`}
          component={asyncComponent(() => import("./voucherPromotion"))}
        />,
        "VoucherPromotion",
        match,
        fullPermission,
        permissionList,
        businessType
      )}
      {checkPermission(
        "membership",
        ["Common-Membership"],
        <Route
          path={`${match.url}/membership`}
          component={asyncComponent(() => import("./membership"))}
        />,
        "",
        match,
        fullPermission,
        permissionList,
        businessType
      )}
      <Route
        path={`${match.url}/dashboard`}
        component={asyncComponent(() => import("./dashboard"))}
      />
      {checkPermission(
        "business-details",
        ["Common-GeneralSetting", "Common-PaymentSetting", "Common-Stores"],
        <Route
          path={`${match.url}/business-details`}
          component={asyncComponent(() => import("./businessDetails"))}
        />,
        "",
        match,
        fullPermission,
        permissionList,
        businessType
      )}
      {checkPermission(
        "home-page",
        ["Common-Design"],
        <Route
          path={`${match.url}/home-page`}
          component={asyncComponent(() => import("./homePageWebsite"))}
        />,
        "",
        match,
        fullPermission,
        permissionList,
        businessType
      )}
      <Route
        path={`${match.url}/profile`}
        component={asyncComponent(() => import("./account"))}
      />
      {checkPermission(
        "email-marketing",
        ["Common-EmailTemplate", "Common-EmailSMTP"],
        <Route
          path={`${match.url}/email-marketing`}
          component={asyncComponent(() => import("./emailMarketing"))}
        />,
        "",
        match,
        fullPermission,
        permissionList,
        businessType
      )}
      {fullPermission ? (
        <Route
          path={`${match.url}/staff`}
          component={asyncComponent(() => import("./staff"))}
        />
      ) : (
        <Redirect exact from={`${match.url}/staff`} to={`/404`} />
      )}
    </Switch>
  );
};

export default withRouter(Routes);
