import { Redirect } from "react-router-dom";
import React from "react";

export const checkPermission = (
  url,
  permissionName,
  children,
  business,
  match,
  fullPermission,
  permissionList,
  businessType
) => {
  var arrayPermission = [];
  permissionName.forEach((item) => {
    permissionList &&
      permissionList.length > 0 &&
      permissionList.forEach((permission) => {
        if (permission.PermissionRecordName === item)
          arrayPermission.push(item);
      });
  });
  if (business === "VoucherPromotion" || business === "BookingOrder") {
    if (
      fullPermission ||
      (arrayPermission.length > 0 && businessType.includes(business))
    )
      return children;
    else return <Redirect exact from={`${match.url}/${url}`} to={`/404`} />;
  } else {
    if (fullPermission || arrayPermission.length > 0) return children;
    else return <Redirect exact from={`${match.url}/${url}`} to={`/404`} />;
  }
};
