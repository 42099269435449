import * as Types from "constants/ActionTypes";

const INIT_STATE = {
  token: JSON.parse(sessionStorage.getItem("tokenBookingOrderAdmin")),
  errorSignIn: false,
  successSignIn: false,
  loader: false,
  messages: "",
  fullPermission: JSON.parse(sessionStorage.getItem("FullPermission")),
  permissionsForUser: JSON.parse(sessionStorage.getItem("PermissionsForUser")),
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Types.SIGN_IN_BOOKING_ORDER_ADMIN_SUCCESS: {
      return {
        ...state,
        loader: false,
        successSignIn: action.payload,
      };
    }
    case Types.SIGN_IN_BOOKING_ORDER_ADMIN_ERROR: {
      return {
        ...state,
        loader: false,
        messages: action.payload,
        errorSignIn: true,
      };
    }
    case Types.SET_TOKEN_BOOKING_ORDER_ADMIN: {
      sessionStorage.setItem(
        "tokenBookingOrderAdmin",
        JSON.stringify(action.payload)
      );
      return {
        ...state,
        token: action.payload,
      };
    }
    case Types.USER_BUSINESS_TYPE_SET: {
      localStorage.setItem("businessType", JSON.stringify(action.payload));
      return {
        ...state,
        businessType: action.payload,
      };
    }
    case Types.USER_BUSINESS_CUSTOMER_SET: {
      localStorage.setItem("customerBusiness", JSON.stringify(action.payload));
      return {
        ...state,
        customerBusiness: action.payload,
      };
    }
    case Types.ALL_CUSTOMER: {
      localStorage.setItem("allCustomer", JSON.stringify(action.payload));
      return {
        ...state,
        customerBusiness: action.payload,
      };
    }
    case Types.USER_BUSINESS_PERMISSION_SET: {
      sessionStorage.setItem(
        "FullPermission",
        JSON.stringify(action.payload.FullPermission)
      );
      sessionStorage.setItem(
        "PermissionsForUser",
        JSON.stringify(action.payload.PermissionsForUser)
      );
      return {
        ...state,
        FullPermission: action.payload.FullPermission,
        PermissionsForUser: action.payload.PermissionsForUser,
      };
    }
    default:
      return state;
  }
};
