import React, { useEffect } from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";

import configureStore, { history } from "./store";
import "./firebase/firebase";
import AppAdministrator from "./containers/administrator/App";
import AppBookingOrderAdmin from "./containers/bookingOrderAdmin/App";
import { getHost } from "util/getHost";
import Error404 from "./app/routes/extraPages/404";

export const store = configureStore();

const RestrictedRouteAdministrator = ({
  component: Component,
  getHost,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      getHost == "admin.ez4shop.com" ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/404",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const RestrictedRouteBookingOrderAdmin = ({
  component: Component,
  getHost,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      getHost == "client.ez4shop.com" ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/404",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const MainApp = () => {
  useEffect(() => {
    if (
      window.location.href === "https://admin.ez4shop.com/" ||
      window.location.href === "http://admin.ez4shop.com/"
    )
      window.location.href = "https://admin.ez4shop.com/administrator";
    else if (
      window.location.href === "https://client.ez4shop.com/" ||
      window.location.href === "http://client.ez4shop.com/"
    )
      window.location.href = "https://client.ez4shop.com/bookingOrderAdmin";
  }, []);
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <RestrictedRouteAdministrator
            path={`/administrator`}
            getHost={getHost()}
            component={AppAdministrator}
          />
          <RestrictedRouteBookingOrderAdmin
            path={`/bookingOrderAdmin`}
            getHost={getHost()}
            component={AppBookingOrderAdmin}
          />
          <Route path="/404" component={Error404} />
        </Switch>
      </ConnectedRouter>
    </Provider>
  );
};

export default MainApp;
