import { all, put, call, take, fork, takeLatest } from "redux-saga/effects";
import { getProductId } from 'service/bookingOrderAdmin/ProductBookingOrderAdmin'

import {
  isLoadingProduct,
  onSuccesAddAttribute,
  getProductSucces
} from "actions";

import { GET_PRODUCT, ON_ADD_ATTRIBUTE } from "constants/ActionTypes";

function* getProductSaga({ payload }) {
  yield put(isLoadingProduct(true));
  const { id } = payload;
  let response = yield call(getProductId, id);
  if (response.status === 200) {
    yield put(getProductSucces(response.data))
  }
}

function* addAttributeSaga(Product_ProductAttributes) {
  try {
    if (Product_ProductAttributes) {
      onSuccesAddAttribute(Product_ProductAttributes)
    }
  } catch (e) {

  }
}

function* watchingProductSaga() {
  yield takeLatest(GET_PRODUCT, getProductSaga);
}

function* watchingAddAttributeSaga() {
  const { payload } = yield take(ON_ADD_ATTRIBUTE);
  yield fork(addAttributeSaga, payload)
}

export default function* rootSaga() {
  yield all([
    fork(watchingProductSaga),
    fork(watchingAddAttributeSaga)
  ])
}