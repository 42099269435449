import axios from "util/Api";
import * as Urls from "constants/Config";

const userSignInAdmin = async (email, password) => {
  return await axios
    .post(`${Urls.API_URL}CommonAdministrator/SignInAdministrator`, {
      Username: "string",
      Email: email,
      Password: password,
      RoleId: 0,
    })
    .then((data) => {
      return data;
    })
    .catch(function(error) {
      console.log("Error****:", error.message);
      return error.response;
    });
};

export const apiAdmin = {
  userSignInAdmin,
};
