
import request from '../../util/Api';
import { API_URL } from '../../constants/Config';
class MyUploadAdapter {
  constructor(loader, token) {
    // The file loader instance to use during the upload.
    this.loader = loader;
    this.token = token;
    this.upload = this.upload.bind(this);
    this.abort = this.abort.bind(this);
  }

  // Starts the upload process.
  upload() {
    return this.loader.file.then(file => {
      const data = new FormData();
      data.set('EntityId', 0);
      data.set('formFile', file);
      data.set('Id', 0);
      return request({
        url: 'CommonBookingOrderAdmin/SaveImageAndInsertDatabase',
        method: 'POST',
        data: data,
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
        .then(res => {

          var resData = res.data;
          let image_url = `${API_URL}/${resData.Data}`;
          resData.default = image_url;
          // console.log('upload data: ',resData);

          return resData;
        })
        .catch(error => {
          console.log('upload data failed: ', error);

          return Promise.reject(error);
        });
    });
  }

  // Aborts the upload process.
  abort() {
    // Reject promise returned from upload() method.
  }
}

export default MyUploadAdapter
