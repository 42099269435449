import React from "react";
import CustomScrollbars from "util/CustomScrollbars";
import Navigation from "../../components/Navigation";
const SideBarContent = () => {
  const navigationMenus = [
    {
      name: "pages.dashboard",
      type: "item",
      link: "/administrator/app/dashboard",
    },
    {
      name: "none",
      type: "section",
      children: [
        {
          name: "sidebar.customer",
          type: "item",
          icon: "account-box",
          link: "/administrator/app/customer/list-customers",
        },
      ],
    },
    {
      name: "E-mail Marketing",
      type: "collapse",
      icon: "shopping-basket",
      children: [
        {
          name: "Template",
          type: "item",
          icon: "money",
          link: "/administrator/app/email-marketing/list",
        },
        {
          name: "Email Campaign",
          type: "item",
          icon: "email",
          link: "/administrator/app/email-marketing/email-campaign/list",
        },
      ],
    },
    {
      name: "sidebar.setting",
      type: "collapse",
      icon: "settings-square",
      children: [
        {
          name: "General",
          type: "item",
          icon: "store",
          link: "/administrator/app/settings/general-settings",
        },
        {
          name: "Payment",
          type: "item",
          icon: "code-setting",
          link: "/administrator/app/settings/payment-settings",
        },
        {
          name: "Modules",
          type: "item",
          icon: "comment-alt",
          link: "/administrator/app/settings/module",
        },
        {
          name: "Packages",
          type: "item",
          icon: "collection-text",
          link: "/administrator/app/settings/package/list",
        },
      ],
    },
    {
      name: "sidebar.report",
      type: "collapse",
      icon: "chart",
      children: [
        {
          name: "Insights",
          type: "item",
          icon: "money-box",
          link: "/administrator/app/report/insights",
        },
      ],
    },
  ];
  return (
    <CustomScrollbars className=" scrollbar">
      <Navigation menuItems={navigationMenus} />
    </CustomScrollbars>
  );
};
export default SideBarContent;
