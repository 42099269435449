import * as Types from "constants/ActionTypes";

const INIT_STATE = {
  token: JSON.parse(sessionStorage.getItem("tokenAdministrator")),
  errorSignIn: false,
  successSignIn: false,
  loader: false,
  messages: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Types.SIGN_IN_ADMINISTRATOR_SUCCESS: {
      return {
        ...state,
        loader: false,
        successSignIn: action.payload,
      };
    }
    case Types.SIGN_IN_ADMINISTRATOR_ERROR: {
      return {
        ...state,
        loader: false,
        messages: action.payload,
        errorSignIn: true,
      };
    }
    case Types.SET_TOKEN_ADMINISTRATOR: {
      sessionStorage.setItem(
        "tokenAdministrator",
        JSON.stringify(action.payload)
      );
      return {
        ...state,
        token: action.payload,
      };
    }
    default:
      return state;
  }
};
